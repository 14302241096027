.p-t-13 {
    padding-top: 13px;
}

.p-t-45 {
    padding-top: 45px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-l-30 {
    padding-left: 30px;
}


.p-l-41 {
    padding-left: 41px;
}

.p-l-15 {
    padding-left: 15px;
}

.p-l-10 {
    padding-left: 10px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-15 {
    margin-left: 15px;
}

.f-r {
    float: right !important;
}

.p-2-1 {
    padding: 0.2rem 0.5rem !important;
}

.p-start-0 {
    padding-inline-start: 0 !important;
}

.p-start-1 {
    padding-inline-start: 0.5em !important;
}

.p-start-2 {
    padding-inline-start: 1em !important;
}

.p-start-3 {
    padding-inline-start: 1.3em !important;
}

.p-start-4 {
    padding-inline-start: 1.8em !important;
}


.p-end-0 {
    padding-inline-end: 0 !important;
}

.p-end-1 {
    padding-inline-end: 0.5em !important;
}

.p-end-2 {
    padding-inline-end: 1em !important;
}

.p-end-3 {
    padding-inline-end: 1.3em !important;
}

.m-start-0 {
    margin-inline-start: 0 !important;
}

.m-start-1 {
    margin-inline-start: 0.5em !important;
}

.m-start-2 {
    margin-inline-start: 1em !important;
}

.m-start-3 {
    margin-inline-start: 1.3em !important;
}

.m-start-4 {
    margin-inline-start: 1.7em !important;
}

.m-end-0 {
    margin-inline-end: 0 !important;
}

.m-end-1 {
    margin-inline-end: 0.5em !important;
}

.m-end-2 {
    margin-inline-end: 1em !important;
}

.m-end-3 {
    margin-inline-end: 1.3em !important;
}

.m-end-4 {
    margin-inline-end: 1.7em !important;
}

.m-t-20 {
    margin-top: 20px;
}

.no-padding {
    padding: unset !important;
}