/* ========================================================================
     Component: placeholder
 ========================================================================== */

// Defines styles for placeholder boxes
// Create a placeholder space that can be used for uploading files via drag and drop.
// Based on uikit placeholder addon
// ==========================================================================
// Variables
// ==========================================================================
$placeholder-margin-vertical : 15px;
$placeholder-padding : 20px;
$placeholder-border : #ddd;
$placeholder-background : #fafafa;
$placeholder-color : #8F99A8;
//for test : //$placeholder-color :red;

$placeholder-large-padding-vertical : 80px;


// Placeholder container
.box-placeholder {
    margin-bottom: $placeholder-margin-vertical;
    padding: $placeholder-padding;
    border: 1px dashed $placeholder-border;
    background: $placeholder-background;
    color: $placeholder-color;
}


// Remove margin from the last-child 
.box-placeholder> :last-child {
    margin-bottom: 0;
}

// Variant
.box-placeholder-lg {
    padding-top: $placeholder-large-padding-vertical;
    padding-bottom: $placeholder-large-padding-vertical;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholder-color !important;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder-color !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholder-color !important;
  }

  .select-placeholder-text
  {
    color: $placeholder-color !important;
  }