// Cutoms Gray colors for theme
$gray-darker: #252A31;
$gray-dark: #383E47;
$gray: #DCE0E5;
$gray-light: #E5E8EB;
$gray-lighter: #EDEFF2;

// Custom brand colors for theme
$inverse: #1C2127;
$purple: #7961DB;
$pink: #F5498B;
$yellow: #FBD065;
$green: #29A634;

$primary: #215DB0;
$secondary: #FFFFFF;
$success: #32A467;
$info: #4C90F0;
$warning: #EC9A3C;
$danger: #E76A6E;
$dark: #383E47;

// Custom color variants
$amountOfLight: 10%;

$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height: 59px;
$top-notif-message-height: 30px;

// Button secondary redefinition style for theme
$btn-secondary-color: #383E47;
$btn-secondary-bg: #FFFFFF;
$btn-secondary-border: var(--btn-secondary-border);

// Top Border color for custom default cards (.card-default)
$card-default-border-color: #D3D8DE;

// ---------------------
// Utilities variables
// ---------------------

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);

$font-size-base: 1rem;
$text-sm: $font-size-base * 0.74375;
$text-md: $font-size-base * 1.6625;
$text-lg: $font-size-base * 2.625;

$border-color: rgba(0, 0, 0, 0.12);

$wd-xxxs: 32px;
$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
    "purple": $purple,
    "pink": $pink,
    "yellow": $yellow,
    "green": $green,
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "inverse": $inverse,
    "primary-light": $primary-light,
    "primary-dark": $primary-dark,
    "success-light": $success-light,
    "success-dark": $success-dark,
    "info-light": $info-light,
    "info-dark": $info-dark,
    "warning-light": $warning-light,
    "warning-dark": $warning-dark,
    "danger-light": $danger-light,
    "danger-dark": $danger-dark,
    "green-light": $green-light,
    "green-dark": $green-dark,
    "pink-light": $pink-light,
    "pink-dark": $pink-dark,
    "purple-light": $purple-light,
    "purple-dark": $purple-dark,
    "inverse-light": $inverse-light,
    "inverse-dark": $inverse-dark,
    "yellow-light": $yellow-light,
    "yellow-dark": $yellow-dark,
);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
);

// Body

$body-bg: #F6F7F9;
$body-color: #5F6B7C;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

$text-muted: #909fa7;

// Tables

$table-accent-bg: #F6F7F9;
$table-hover-bg: #F6F7F9;

$table-border-color: #E5E8EB;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #8ABBFF;
$input-placeholder-color: var(--border);

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

$custom-control-indicator-active-bg: lighten($primary, 25%);

// Dropdowns

$dropdown-border-color: #E5E8EB;

// Navs

$nav-tabs-link-active-bg: #FFFFFF;

// Jumbotron

$jumbotron-bg: #F6F7F9;

// List group

$list-group-border-color: #E5E8EB;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Breadcrumbs

$breadcrumb-bg: #F6F7F9;
$breadcrumb-divider-color: #C5CBD3;
$breadcrumb-active-color: $body-color;

$modal-md: 466px;